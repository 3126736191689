import React from "react";
// import resumeFile from "../documents/resume.pdf";

const Resume = () => {
  const experienceDetails = [
    {
      yearRange: "junio 2024 - noviembre 2024",
      title: "Software Engineer",
      place: "A1M Entertainment",
      desc: `Departamento de Ingeniería. Trabajo con fluidez tanto en el front-end (React, TypeScript y MUI) como en el back (Prisma, Next.js y GraphQL).
            Desarrollamos soluciones web escalables e implementamos mejoras en la plataforma (nuevos torneos, paneles de admin, Metabase, …) así como el despliegue de la infraestructura en AWS, con herramientas como ECS, Cloudfront, RDS, Lambda, S3 y EC2 entre otras.`,
      img: "images/resume/logo-a1m.png",
    },
    {
      yearRange: "2021 - 2023",
      title: "Web developer",
      place: "Liga Canaria de Esports",
      desc: "Desarrollo de proyectos web de la Liga Canaria de Esports HiperDino y Tenerife GG. Desarrollo mayoritariamente front-end usando tecnologías como React, GraphQL y MUI, siempre con TypeScript, pero también desarrollando back-end en Node.JS usando el ORM Prisma. Desarrollo de las webs de Liga Canaria de Esports, Simseries, Tenerife GG y Gamelab Tenerife.",
      img: "images/resume/logo-lces.png",
    },
    {
      yearRange: "2015 - 2020",
      title: "Project Manager",
      place: "Gaming Experience",
      desc: "Planificación y ejecución de proyectos tecnológicos, así como la coordinación de los equipos de trabajo. Algunos de los proyectos desarrollados fueron: TGX, Juegos Escolares Minecraft Education (JEME), Liga Canaria de Esports HiperDino (LCES) e Intel Rising e-Stars entre otros",
      img: "images/resume/logo-gx.png",
    },
    // {
    //   yearRange: "2011 - 2013",
    //   title: "Innova7",
    //   place: "Social Media Manager & Esports Especialist",
    //   desc: "Organizador de eventos tecnológicos como TLP Tenerife. Responsable de Social Media y gestión de Esports",
    //   img: "images/resume/logo-innova7.png",
    // },
  ];

  const educationDetails = [
    {
      yearRange: "2020 - 2025",
      title: "Grado en Ingeniería Informática",
      place: "Universidad VIU",
      desc: "Actualmente finalizando mis estudios del Grado en Ingeniería Informática en la Universidad Internacional de Valencia con un 9,3 de nota media. Mención en Tecnologías de la Información: administrador de sistemas, ciberseguridad y desarrollo web. Trabajando en la actualidad en el Trabajo de Fin de Grado (TFG) sobre la creación de una aplicación web para gestionar presupuestos familiares con IA (LLM preentrenado).",
      img: "images/resume/logo-viu.png",
    },
    {
      yearRange: "2020 - 2020",
      title: "Curso de Especialización en Python",
      place: "Escuela de Organización Industrial (EOI)",
      desc: "Curso de especialización en Python, con una duración de 355 horas. Desarrollo web con Django, testing TDD, desarrollo de apps de escritorio, videojuegos, machine learning, infraestructura y despliegue así como dispositivos IoT.",
      img: "images/resume/logo-eoi.jpeg",
    },
    {
      yearRange: "2013 - 2015",
      title: "Ciclo Superior en Administración y Finanzas",
      place: "IES Óscar Domínguez",
      desc: "Capacitado para la organización y realización de la administración y gestión de personal, las operaciones económico-financieras y la información y asesoramiento a clientes y usuarios.",
      img: "images/resume/logo-ies.png",
    },
  ];

  const skills = [
    {
      name: "React",
      startDate: new Date("2021-11-01"),
    },
    {
      name: "TypeScript / JavaScript",
      startDate: new Date("2021-11-01"),
    },
    {
      name: "GraphQL",
      startDate: new Date("2021-11-01"),
    },
    {
      name: "Node.js",
      startDate: new Date("2023-01-01"),
    },
    {
      name: "Prisma",
      startDate: new Date("2023-01-01"),
    },
    {
      name: "AWS",
      startDate: new Date("2024-06-01"),
    },
  ];

  const calculateExperience = (startDate) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const november30 = new Date(currentYear, 10, 30);

    const monthsDifference =
      (currentDate.getFullYear() - startDate.getFullYear()) * 12 +
      (currentDate.getMonth() - startDate.getMonth());

    const adjustedMonthsDifference =
      currentDate < november30 ? monthsDifference - 1 : monthsDifference;

    const experience = adjustedMonthsDifference / 12;

    return experience.toFixed(1);
  };

  const updatedSkills = skills.map((skill) => ({
    ...skill,
    experience: calculateExperience(skill.startDate),
  }));

  return (
    <section id="resume" className="section">
      <div className="container">
        {/* Heading */}
        <p className=" text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Resumen</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Un resumen de mi currículum
        </h2>
        {/* Heading end*/}
        <div className="row g-5 mt-5">
          {/* My Experience */}
          <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="text-7 fw-600 mb-4 pb-2">Mi experiencia</h2>
            <div className="border-start border-2 border-primary ps-3">
              {experienceDetails.length > 0 &&
                experienceDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    {/* LOGO */}
                    <img
                      src={value.img}
                      alt="logo"
                      style={
                        window.innerWidth > 768
                          ? {
                              height: "60px",
                              width: "auto",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }
                          : {
                              height: "auto",
                              maxWidth: "80vw",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }
                      }
                    />
                    <p
                      className="text-muted"
                      style={{
                        minHeight:
                          window.innerWidth > 768 ? "180px" : "inherit",
                      }}
                    >
                      {value.desc}
                    </p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>

          {/* My Education */}
          <div className="col-lg-6 wow fadeInUp">
            <h2 className="text-7 fw-600 mb-4 pb-2">Mi educación</h2>
            <div className="border-start border-2 border-primary ps-3">
              {educationDetails.length > 0 &&
                educationDetails.map((value, index) => (
                  <div key={index}>
                    <h3 className="text-5">{value.title}</h3>
                    <p className="mb-2">
                      {value.place} / {value.yearRange}
                    </p>
                    {/* LOGO */}
                    <img
                      src={value.img}
                      alt="logo"
                      style={
                        window.innerWidth > 768
                          ? {
                              height: "60px",
                              width: "auto",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }
                          : {
                              height: "auto",
                              maxWidth: "80vw",
                              marginTop: "10px",
                              marginBottom: "10px",
                            }
                      }
                    />
                    <p
                      className="text-muted"
                      style={{
                        minHeight:
                          window.innerWidth > 768 ? "180px" : "inherit",
                      }}
                    >
                      {value.desc}
                    </p>
                    <hr className="my-4" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        {/* Mis habilidades */}
        <h2 className="text-7 fw-600 mb-4 pb-2 mt-5 wow fadeInUp">
          Mis habilidades
        </h2>
        <div className="row gx-5">
          {updatedSkills.length > 0 &&
            updatedSkills.map((skill, index) => (
              <div className="col-md-6 wow fadeInUp" key={index}>
                <p className="fw-500 text-start mb-2">
                  {skill.name}{" "}
                  <span className="float-end">
                    {skill.experience} {skill.experience === 1 ? "año" : "años"}
                  </span>
                </p>
                <div className="progress progress-sm mb-4">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: skill.experience * 40 + "%" }}
                    aria-valuenow={skill.experience}
                    aria-valuemin={0}
                    aria-valuemax={new Date().getFullYear() - 2023}
                  />
                </div>
              </div>
            ))}
        </div>
        {/* <p className="text-center mt-5 wow fadeInUp">
          <a
            className="btn btn-outline-dark shadow-none rounded-0"
            href={resumeFile}
            download
          >
            Download CV
          </a>
        </p> */}
      </div>
    </section>
  );
};

export default Resume;
