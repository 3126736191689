import React, { useState } from "react";
import { Tooltip } from "../../Tooltip";

const StandardMenuHeader = () => {
  const [isNavModalClose, setIsNavModalClose] = useState(true);

  return (
    <header id="header" className="sticky-top">
      {/* Navbar */}
      <nav className="primary-menu navbar navbar-expand-lg text-uppercase navbar-line-under-text fw-600">
        <div className="container position-relative">
          <div className="col-auto col-lg-2 d-inline-flex ps-lg-0">
            {/* Logo */}
            <a
              className="logo"
              title="Rober Herraiz"
              // smooth="easeInOutQuint"
              // duration={scrollDuration}
              style={{ cursor: "pointer" }}
              // offset={-72}
              href="/"
              onClick={(e) => {
                setIsNavModalClose(true);
              }}
            >
              <img
                style={{ height: "20px" }}
                src="images/logo.png"
                alt="Rober Herraiz"
              />
            </a>
            {/* Logo End */}
          </div>

          <div className="col col-lg-8 navbar-accordion px-0">
            <button
              className={
                "navbar-toggler ms-auto collapsed " +
                (isNavModalClose ? "" : "show")
              }
              type="button"
              onClick={() => setIsNavModalClose(!isNavModalClose)}
            >
              <span />
              <span />
              <span />
            </button>
            <div
              id="header-nav"
              className={
                "collapse navbar-collapse justify-content-center " +
                (isNavModalClose ? "" : "show")
              }
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a
                    className="nav-link "
                    // smooth="easeInOutQuint"
                    // duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    // activeClass="active"
                    // spy
                    href="/"
                    // offset={-71}
                    onClick={(e) => {
                      setIsNavModalClose(true);
                    }}
                  >
                    Inicio
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    // smooth="easeInOutQuint"
                    // duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    // spy
                    href="/#about"
                    onClick={(e) => {
                      setIsNavModalClose(true);
                    }}
                  >
                    Sobre mi
                  </a>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="services"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Services
                  </Link>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link "
                    // smooth="easeInOutQuint"
                    // duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    // activeClass="active"
                    // spy
                    href="/#resume"
                    onClick={(e) => {
                      setIsNavModalClose(true);
                    }}
                  >
                    Resumen
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    // smooth="easeInOutQuint"
                    // duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    // activeClass="active"
                    // spy
                    href="/#portfolio"
                    onClick={(e) => {
                      setIsNavModalClose(true);
                    }}
                  >
                    Portfolio
                  </a>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="faq"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    FAQ
                  </Link>
                </li> */}
                {/* <li className="nav-item">
                  <Link
                    className="nav-link "
                    smooth="easeInOutQuint"
                    duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    activeClass="active"
                    spy
                    to="testimonial"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsNavModalClose(true);
                    }}
                  >
                    Client
                  </Link>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link "
                    // smooth="easeInOutQuint"
                    // duration={scrollDuration}
                    style={{ cursor: "pointer" }}
                    // activeClass="active"
                    // spy
                    href="/#contact"
                    onClick={(e) => {
                      setIsNavModalClose(true);
                    }}
                  >
                    Contacto
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {window.innerWidth > 991 && (
            <div className="col-auto col-lg-2 d-flex justify-content-end ps-0">
              <ul className="social-icons">
                <li className="social-icons-linkedin">
                  <Tooltip text="Linkedin" placement="bottom">
                    <a
                      href="https://www.linkedin.com/in/roberherraiz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-linkedin" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-github">
                  <Tooltip text="Github" placement="bottom">
                    <a
                      href="http://www.github.com/Herraiz"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-github" />
                    </a>
                  </Tooltip>
                </li>
                <li className="social-icons-twitter">
                  <Tooltip text="X - Twitter" placement="bottom">
                    <a
                      href="https://x.com/roberherraiz/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fab fa-twitter" />
                    </a>
                  </Tooltip>
                </li>
              </ul>
            </div>
          )}
        </div>
      </nav>
      {/* Navbar End */}
    </header>
  );
};

export default StandardMenuHeader;
