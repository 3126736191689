import React from "react";
const AboutUs = () => {
  return (
    <section id="about" className="section">
      <div className="container">
        {/* Heading */}
        <p className="text-center mb-2 wow fadeInUp">
          <span className="bg-primary text-dark px-2">Sobre mi</span>
        </p>
        <h2 className="text-10 fw-600 text-center mb-5 wow fadeInUp">
          Conóceme mejor
        </h2>
        {/* Heading end*/}
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start wow fadeInUp">
            {/* md up */}
            {window.innerWidth > 768 ? (
              <h2 className="text-8 fw-400 mb-3">
                ¡Hola!, soy{" "}
                <span className="fw-700 border-bottom border-3 border-primary">
                  Rober Herraiz
                </span>{" "}
                💻 👨‍🎓 🐈‍⬛ 🏍️ 🚴 🎹
              </h2>
            ) : (
              <h2 className="text-8 fw-400 mb-3">
                ¡Hola!, soy <br />
                <span className="fw-700 border-bottom border-3 border-primary">
                  Rober Herraiz
                </span>
                <br />
                <p style={{ marginTop: "5px" }}>💻 👨‍🎓 🐈‍⬛ 🏍️ 🚴 🎹</p>
              </h2>
            )}

            <p className="text-5">
              Llevo dos años y medio trabajando como ingeniero de software,
              trabajando el front (React) y el back (Node.js, Prisma y GraphQL),
              y realizando la insfraestructura y el despliegue de las
              aplicaciones en AWS. Estoy en mi último año de Ingeniería
              Informática y en mi tiempo libre aprendo a tocar el piano, me
              encanta montar en moto, perderme con la bici en el monte y también
              hacer pan.
            </p>
          </div>
          <div
            className="col-lg-4 mt-4 mt-lg-0 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            <div className="featured-box style-4">
              <div className="featured-box-icon text-20 fw-500 bg-primary rounded-circle">
                <span className="wow heartBeat" data-wow-delay="1.3s">
                  8
                </span>
              </div>
              <h3 className="text-7 wow rubberBand" data-wow-delay="2s">
                años en la <span className="fw-700">industria tech</span>
              </h3>
            </div>
          </div>
        </div>
        <div className="row gy-3 mt-4">
          <div className="col-6 col-lg-3 wow fadeInUp">
            <p className="text-muted fw-500 mb-0">Nombre:</p>
            <p className="text-4 text-dark fw-600 mb-0">
              Roberto Alonso Herraiz
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.2s">
            <p className="text-muted fw-500 mb-0">Email:</p>
            <p
              className={
                window.innerWidth > 768
                  ? "text-4 fw-600 mb-0"
                  : "text-2 fw-600 mb-0"
              }
            >
              <a className="link-dark" href="mailto:hello@roberherraiz.com">
                hello@roberherraiz.com
              </a>
            </p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.3s">
            <p className="text-muted fw-500 mb-0">Fecha de nacimiento:</p>
            <p className="text-4 text-dark fw-600 mb-0">Septiembre, 1992</p>
          </div>
          <div className="col-6 col-lg-3 wow fadeInUp" data-wow-delay="0.4s">
            <p className="text-muted fw-500 mb-0">De:</p>
            <p className="text-4 text-dark fw-600 mb-0">
              Islas Canarias 🇮🇨 - España 🇪🇸
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
