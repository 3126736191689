import React from "react";
import "../App.scss";
// import WOW from "wowjs";
import AboutUs from "./About";
// import Services from "./Services";
import Resume from "./Resume";
import Portfolio from "./Portfolio";
// import Testimonials from "./Testimonials";
import Contact from "./Contact";
// import Footer from "./Footer";
// import { useEffect, useState } from "react";
import {
  appliedConfig,
  introBackgroundConfig,
  themeConfig,
} from "../config/commonConfig";
// import { Tooltip } from "./Tooltip";
// import CallToAction from "./CallToAction";
// import FAQs from "./FAQs";

import FullScreenVideoBgIntro from "./themes/fullScreen/IntroVideoBg";
// import FullScreenHeader from "./themes/fullScreen/Header";
import FullScreenDefaultIntro from "./themes/fullScreen/IntroDefault";
import FullScreenImgBgIntro from "./themes/fullScreen/IntroImageBg";
// import BottomHeader from "./themes/bottomHeader/Header";
// import StandardMenuHeader from "./themes/StandardMenu/Header";
// import BottomHeaderDefaultIntro from "./themes/bottomHeader/IntroDefault";
// import BottomHeaderImgBgIntro from "./themes/bottomHeader/IntroImageBg";
// import BottomHeaderVideoBgIntro from "./themes/bottomHeader/IntroVideoBg";
import StandardMenuDefaultIntro from "./themes/StandardMenu/IntroDefault";
import StandardMenuImgBgIntro from "./themes/StandardMenu/IntroImageBg";
import StandardMenuVideoBgIntro from "./themes/StandardMenu/IntroVideoBg";

const Home = () => {
  const appliedTheme = appliedConfig.appliedTheme;
  const appliedIntro = appliedConfig.appliedIntro;

  const getFullScreenIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <FullScreenDefaultIntro></FullScreenDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <FullScreenImgBgIntro></FullScreenImgBgIntro>;
    } else {
      return <FullScreenVideoBgIntro></FullScreenVideoBgIntro>;
    }
  };

  const getStandardMenuIntro = () => {
    if (appliedIntro === introBackgroundConfig.default) {
      return <StandardMenuDefaultIntro></StandardMenuDefaultIntro>;
    } else if (appliedIntro === introBackgroundConfig.image) {
      return <StandardMenuImgBgIntro></StandardMenuImgBgIntro>;
    } else {
      return <StandardMenuVideoBgIntro></StandardMenuVideoBgIntro>;
    }
  };

  return (
    <>
      <div id="content" role="main">
        {appliedTheme === themeConfig.FullScreenMenu && getFullScreenIntro()}
        {appliedTheme === themeConfig.StandardMenu && getStandardMenuIntro()}
      </div>
      <div id="about" style={{ backgroundColor: "#fff" }}>
        <AboutUs />
      </div>
      {/* <Services></Services> */}
      <div id="resume" style={{ backgroundColor: "#fff" }}>
        <Resume />
      </div>
      <div id="portfolio">
        <Portfolio />
      </div>
      {/* <CallToAction></CallToAction> */}
      {/* <FAQs></FAQs> */}
      {/* <Testimonials></Testimonials> */}
      <div id="contact">
        <Contact />
      </div>
    </>
  );
};

export default Home;
